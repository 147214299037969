import React, { useState } from "react";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { Moment } from "moment";
import {
  styled,
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core";
const dateIcon = require("./date-icon.svg");
const dateIconWhite = require("./date-icon-white.svg");

const theme = createTheme({
  palette: {
    primary: {
      main: "#01AA7F",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginRight: "5px",
    paddingLeft: (props: IDateRangePickerProps) =>
      props.selectedDate ? 28 : 18,
    [theme.breakpoints.down("md")]: {
      paddingLeft: (props: IDateRangePickerProps) =>
        props.selectedDate ? 28 : 18,
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: (props: IDateRangePickerProps) =>
        props.selectedDate ? 28 : 18,
    },
  },
  popup: {
    "& .MuiPickersCalendarHeader-iconButton": {
      color: "#01AA7F !important",
    },
  },
}));

const StyledDateRangePicker = styled(KeyboardDatePicker)({
  "& .MuiInput-root": {
    backgroundColor: "#002348",
    borderRadius: "8px",
    height: "46px",
    width: "161px",
    display: "flex",
    flexDirection: "row-reverse",
    color: "#FFF",
    fontFamily: "Open Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
  },
  "& .MuiInput-underline::before": {
    content: "",
    border: "1px solid #B4B4B4",
    borderRadius: "8px",
    height: "46px",
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
    border: "1px solid #B4B4B4",
    height: "46px",
  },
  "& .MuiInput-underline::after": {
    content: "",
    border: "1px solid #B4B4B4",
    borderRadius: "8px",
    transition: "0s",
    height: "46px",
  },
  "& .MuiInput-underline.Mui-focused::after": {
    border: "1px solid #B4B4B4",
    transition: "0s",
    height: "46px",
  },
});

interface IDateRangePickerProps {
  selectedDate: Moment | null;
  handleDateChange: (date: Moment | null) => void;
}

const CustomDateRangePicker: React.FC<IDateRangePickerProps> = ({
  selectedDate,
  handleDateChange,
}: IDateRangePickerProps) => {
  const classes = useStyles({ selectedDate, handleDateChange });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [openView, setOpenView] = useState<"year" | "month">("year");
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const openDateRangePicker: React.MouseEventHandler<HTMLDivElement> = (
    event
  ) => {
    if (event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };

  const closeDatePicker = () => {
    setAnchorEl(null);
  };

  const onDateChange = (date: Moment | null) => {
    if (openView === "year") {
      setSelectedYear(date?.year() || null);
      setOpenView("month");
    } else {
      if (selectedYear && date) {
        setAnchorEl(null);
        const finalDate = date.clone().year(selectedYear);
        setOpenView("year");
        setSelectedYear(null);
        handleDateChange(finalDate);
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StyledDateRangePicker
          data-test-id="date-picker"
          disableToolbar
          variant="inline"
          format="MM/YYYY"
          margin="normal"
          label=""
          openTo={openView}
          views={["year", "month"]}
          value={selectedDate}
          onChange={onDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
            className: classes.iconButton,
            size: "small",
          }}
          helperText=""
          error={false}
          keyboardIcon={
            <img
              src={selectedDate ? dateIconWhite : dateIcon}
              alt="date"
              height={16}
            />
          }
          onClick={openDateRangePicker}
          placeholder="Select Date"
          PopoverProps={{
            classes: {
              paper: classes.popup,
            },
            anchorEl: anchorEl,
            open: Boolean(anchorEl),
            onClose: closeDatePicker,
          }}
          InputProps={{
            readOnly: true,
          }}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default CustomDateRangePicker;
